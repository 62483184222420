//== JQUERY
  import $ from 'jquery'
  window.$ = window.jQuery = $

  $(document).ready(function($){
    //
    // MENU MOBILE
    $('.navbar-nav>li>a').on('click', function(){
      $('.navbar-collapse').collapse('hide');
    });

    //
    //
    // FIXED HEADER
    $(window).on('load scroll resize orientationchange', function () {
      let viewportWidth = $(window).width();

      if ($(document).scrollTop()) {
        $('#header').addClass("set-lion");

        if ($(window).width() >= 992){
          $('#navbar_main').css('background', 'rgba(189, 175, 164, .7)');
        }
      } else {
        $('#header').removeClass("set-lion");
        $('#navbar_main').css('background','transparent');
      }
    });
  });


//== BOOTSTRAP
  import { Popper } from '@popperjs/core'
  import { Modal } from 'bootstrap'
  window.Modal = Modal